// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

// Importiere deine Komponenten und Seiten
import Home from './pages/Home';
import Prices from './pages/Prices';
import Analysis from './pages/Analysis';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost'; // Import hinzugefügt
import Profile from './pages/Profile';
import Login from './pages/Login';
import Signup from './pages/Signup';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';

function App() {
  return (
    <AuthProvider>
      <Router>
      <div className="min-h-screen flex flex-col bg-base-100">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/prices" element={<Prices />} />
              <Route
                path="/analysis"
                element={
                  <PrivateRoute>
                    <Analysis />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPost />} /> {/* Route hinzugefügt */}
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </main>
          <footer className="bg-neutral p-4 text-center text-base-content opacity-70">
            © {new Date().getFullYear()} Cryptsight. Alle Rechte vorbehalten.
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;