// src/pages/Login.js
import React, { useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';


function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      await signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value);
      navigate('/');
    } catch {
      setError('Anmeldung fehlgeschlagen');
    }
  }

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl mb-4">Einloggen</h2>
      {error && <p className="mb-4 text-red-500">{error}</p>}
      <form onSubmit={handleSubmit} className="bg-neutral p-6 rounded-lg">
        <div className="mb-4">
          <label className="block mb-2">E-Mail:</label>
          <input
            type="email"
            ref={emailRef}
            required
            className="w-full p-2 rounded bg-base-200 text-base-content"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Passwort:</label>
          <input
            type="password"
            ref={passwordRef}
            required
            className="w-full p-2 rounded bg-base-200 text-base-content"
          />
        </div>
        <button type="submit" className="bg-primary text-base-content px-4 py-2 rounded">
          Einloggen
        </button>
      </form>
      <p className="mt-4">
        Noch kein Konto?{' '}
        <Link to="/signup" className="text-accent">
          Registrieren
        </Link>
      </p>
    </div>
  );
}

export default Login;