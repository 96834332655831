// src/pages/Blog.js
import React from 'react';
import { Link } from 'react-router-dom';
import { posts } from '../data/posts';

function Blog() {
  return (
    <div className="container mx-auto mt-10 text-base-content px-4">
      <h2 className="text-3xl font-bold text-center mb-8">Unser Blog</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {posts.map((post) => (
          <div key={post.id} className="bg-neutral p-6 rounded-lg shadow">
            {post.image && (
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover rounded-md mb-4"
              />
            )}
            <h3 className="text-2xl font-semibold mb-2">{post.title}</h3>
            <p className="text-base-content text-sm opacity-70">{post.date}</p>
            <p className="mt-4">{post.excerpt}</p>
            <Link
              to={`/blog/${post.id}`}
              className="mt-4 inline-block text-accent hover:underline"
            >
              Weiterlesen
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Blog;