// src/pages/Analysis.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import * as tf from '@tensorflow/tfjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Filler
);

const normalizeTensor = (tensor) => {
  const min = tensor.min();
  const max = tensor.max();
  const minVal = min.dataSync()[0];
  const maxVal = max.dataSync()[0];

  if (maxVal === minVal) {
    return { norm: null, min, max, identical: true };
  }

  const norm = tensor.sub(min).div(max.sub(min));
  return { norm, min, max, identical: false };
};

const denormalizeTensor = (tensor, min, max) => {
  return tensor.mul(max.sub(min)).add(min);
};

const createSequences = (tensor, timeStep) => {
  const data = tensor.arraySync();
  const xs = [];
  const ys = [];

  for (let i = 0; i < data.length - timeStep; i++) {
    const x = data.slice(i, i + timeStep);
    const y = data[i + timeStep];
    xs.push(x);
    ys.push(y);
  }

  const xsTensor = tf.tensor2d(xs, [xs.length, timeStep]).reshape([-1, timeStep, 1]);
  const ysTensor = tf.tensor1d(ys);

  return [xsTensor, ysTensor];
};

const createModel = (timeStep) => {
  const model = tf.sequential();
  model.add(tf.layers.lstm({
    units: 16,
    inputShape: [timeStep, 1],
    returnSequences: false,
  }));
  model.add(tf.layers.dense({ units: 1 }));
  model.compile({ optimizer: 'adam', loss: 'meanSquaredError' });
  return model;
};

const trainModel = async (model, xs, ys) => {
  await model.fit(xs, ys, {
    epochs: 10,
    batchSize: 16,
    verbose: 0,
  });
};

function Analysis() {
  const [forecastDays, setForecastDays] = useState(7);
  const [historicalData, setHistoricalData] = useState([]);
  const [forecastData, setForecastData] = useState([]);
  const [loading, setLoading] = useState('fetching');
  const [error, setError] = useState(null);

  const timeStep = 10;

  const calculateForecast = useCallback(async (data, forecastDaysParam) => {
    console.log('Vorhersage wird berechnet...');
    if (data.length === 0) {
      console.warn('Keine Daten für die Vorhersage verfügbar.');
      return [];
    }

    const closePrices = data.map(d => d.y);
    if (closePrices.length < timeStep + 1) {
      console.log('Zu wenige Datenpunkte für Vorhersage. Nur historischer Chart.');
      return [];
    }

    const inputTensor = tf.tensor1d(closePrices);
    const { norm, min, max, identical } = normalizeTensor(inputTensor);

    if (identical || norm === null) {
      console.log('Alle Daten identisch oder fehlerhaft - nur Historie.');
      inputTensor.dispose();
      if (min) min.dispose();
      if (max) max.dispose();
      return [];
    }

    const [xs, ys] = createSequences(norm, timeStep);
    if (xs.shape[0] === 0) {
      console.log('Nicht genug Daten für Sequenzen. Nur Historie.');
      inputTensor.dispose();
      norm.dispose();
      min.dispose();
      max.dispose();
      xs.dispose();
      ys.dispose();
      return [];
    }

    // Zufällige Seeds für etwas Varianz
    tf.util.shuffle(xs);
    tf.util.shuffle(ys);

    const model = createModel(timeStep);
    await trainModel(model, xs, ys);

    const predictions = [];
    let lastSequence = norm
      .slice([norm.shape[0] - timeStep], [timeStep])
      .reshape([1, timeStep, 1]);

    for (let i = 0; i < forecastDaysParam; i++) {
      const prediction = model.predict(lastSequence);
      const predictedValue = prediction.dataSync()[0];
      prediction.dispose();

      if (isNaN(predictedValue)) {
        console.warn('Vorhersage ergab NaN. Breche ab, keine Vorhersage.');
        break;
      }

      predictions.push(predictedValue);

      const sliced = lastSequence.slice([0, 1, 0], [1, timeStep - 1, 1]);
      const predictedValueTensor = tf.tensor3d([[[predictedValue]]], [1, 1, 1]);
      lastSequence = tf.concat([sliced, predictedValueTensor], 1);
      predictedValueTensor.dispose();
    }

    if (predictions.length === 0 || predictions.some(p => isNaN(p))) {
      console.log('Keine gültige Vorhersage berechenbar. Zeige nur Historie.');
      inputTensor.dispose();
      norm.dispose();
      xs.dispose();
      ys.dispose();
      model.dispose();
      min.dispose();
      max.dispose();
      return [];
    }

    const predsTensor = tf.tensor1d(predictions);
    const denormPreds = denormalizeTensor(predsTensor, min, max).arraySync();

    inputTensor.dispose();
    norm.dispose();
    xs.dispose();
    ys.dispose();
    model.dispose();
    min.dispose();
    max.dispose();
    predsTensor.dispose();

    const lastDate = new Date(data[data.length - 1].x);
    const result = denormPreds.map((val, i) => {
      const futureDate = new Date(lastDate);
      futureDate.setDate(futureDate.getDate() + i + 1);
      return { x: futureDate, y: val };
    });

    console.log('Vorhersage abgeschlossen:', result);
    return result;
  }, [timeStep]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading('fetching');
      setError(null);

      try {
        // Wir holen uns 365 Tage an Daten von Coingecko für BTC/USD
        // https://www.coingecko.com/en/api/documentation
        const days = 365;
        const url = `https://api.coingecko.com/api/v3/coins/bitcoin/market_chart`;
        const params = {
          vs_currency: 'usd',
          days: days
        };

        const response = await axios.get(url, { params });
        const data = response.data;
        if (!data.prices || data.prices.length === 0) {
          throw new Error('Keine Preisdaten verfügbar.');
        }

        // data.prices ist ein Array von [timestamp, price]
        // Sortieren nach Zeitstempel (sollte schon sortiert sein)
        const prices = data.prices.map(item => ({
          x: new Date(item[0]),
          y: item[1]
        }));

        console.log('Historische Daten:', prices);
        setHistoricalData(prices);

        setLoading('training');
        const predictions = await calculateForecast(prices, forecastDays);

        if (!predictions || predictions.length === 0) {
          console.log('Keine Vorhersage verfügbar. Zeige nur Historie.');
          setForecastData([]);
        } else {
          setForecastData(predictions);
        }

        setLoading(false);
      } catch (err) {
        console.error('Fehler beim Abrufen der Daten oder Berechnung:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [forecastDays, calculateForecast]);

  const handleForecastDaysChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 && value <= 30) {
      setForecastDays(value);
    }
  };

  if (loading !== false) {
    return (
      <div className="container mx-auto mt-10 text-base-content">
        <p className="text-center text-xl">
          {loading === 'fetching' && 'Daten werden abgerufen...'}
          {loading === 'training' && 'Modell wird trainiert...'}
          {loading === 'predicting' && 'Vorhersage wird berechnet...'}
          {loading !== 'fetching' && loading !== 'training' && loading !== 'predicting' && 'Lade...'}
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto mt-10 text-base-content">
        <p className="text-error">
          Fehler beim Laden der Daten: {error.message}
        </p>
      </div>
    );
  }

  if (historicalData.length === 0) {
    return (
      <div className="container mx-auto mt-10 text-base-content">
        <p>Keine historischen Daten verfügbar.</p>
      </div>
    );
  }

  const chartData = {
    datasets: [
      {
        label: 'Historische Daten',
        data: historicalData.map(d => ({ x: d.x, y: d.y })),
        borderColor: '#1E88E5',
        backgroundColor: 'rgba(30,136,229,0.2)',
        fill: true,
        tension: 0.2,
        pointRadius: 0,
      },
      ...(forecastData.length > 0
        ? [{
            label: 'Vorhersage',
            data: forecastData.map(d => ({ x: d.x, y: d.y })),
            borderColor: '#FB8C00',
            backgroundColor: 'rgba(251,140,0,0.2)',
            borderDash: [5, 5],
            fill: false,
            tension: 0.2,
            pointRadius: 0,
          }]
        : [])
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    parsing: false,
    plugins: {
      legend: {
        labels: {
          color: '#E0E0E0',
        },
      },
      title: {
        display: true,
        text: 'Preisvorhersage für Bitcoin (BTC)',
        color: '#E0E0E0',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: '#2E2E2E',
        titleColor: '#E0E0E0',
        bodyColor: '#E0E0E0',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'dd.MM.yyyy',
        },
        ticks: {
          color: '#E0E0E0',
        },
        grid: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
      y: {
        ticks: {
          color: '#E0E0E0',
          callback: function (value) {
            return '$' + value.toLocaleString();
          },
        },
        grid: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    layout: {
      padding: {
        top: 20,
      },
    },
  };

  return (
    <div className="container mx-auto mt-10 text-base-content px-4" style={{ background: '#1A1A1A', minHeight: '100vh', paddingBottom: '2rem' }}>
      <h2 className="text-3xl font-bold text-center mb-8 text-primary">
        Analyse und Vorhersage
      </h2>

      <div className="flex flex-col md:flex-row justify-between mb-8">
        <div>
          <label className="block mb-2 font-semibold">
            Vorhersagezeitraum (Tage):
          </label>
          <input
            type="number"
            value={forecastDays}
            onChange={handleForecastDaysChange}
            min="1"
            max="30"
            className="bg-neutral text-base-content p-2 rounded focus:outline-none focus:ring-2 focus:ring-primary w-full"
          />
        </div>
      </div>

      <div className="mb-12" style={{ height: '500px' }}>
        <Line data={chartData} options={chartOptions} />
      </div>

      <div className="bg-neutral p-4 rounded mb-4">
        <p className="text-sm text-gray-300">
          * Die Vorhersage basiert auf einem einfachen LSTM-Modell und dient nur zu
          Illustrationszwecken. Keine finanzielle Beratung.
        </p>
      </div>
    </div>
  );
}

export default Analysis;