// src/pages/BlogPost.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { posts } from '../data/posts';
import ReactMarkdown from 'react-markdown';

function BlogPost() {
  const { id } = useParams();
  const post = posts.find((p) => p.id === parseInt(id));

  if (!post) {
    return (
      <div className="container mx-auto mt-10 text-base-content">
        <p>Beitrag nicht gefunden.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-10 text-base-content px-4">
      <Link to="/blog" className="text-accent hover:underline">
        &larr; Zurück zum Blog
      </Link>
      <div className="mt-4">
        <h1 className="text-4xl font-bold mb-2">{post.title}</h1>
        <p className="text-base-content text-sm mb-6">{post.date}</p>
        {post.image && (
          <img
            src={post.image}
            alt={post.title}
            className="w-full h-64 object-cover rounded-md mb-6"
          />
        )}
        <div className="prose prose-invert">
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;