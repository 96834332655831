// src/pages/Prices.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PriceRow from '../components/PriceRow';
import { ClipLoader } from 'react-spinners';

function Prices() {
  const [coins, setCoins] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Daten von der CoinGecko API abrufen
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/markets',
          {
            params: {
              vs_currency: 'usd',
              order: 'market_cap_desc',
              per_page: 100,
              page: 1,
              sparkline: false,
            },
          }
        );
        setCoins(response.data);
      } catch (err) {
        console.error('Fehler beim Abrufen der Daten:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const filteredCoins = coins.filter((coin) =>
    coin.name.toLowerCase().includes(search.toLowerCase()) ||
    coin.symbol.toLowerCase().includes(search.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#1E88E5" size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto mt-10 text-base-content">
        <p>Fehler beim Laden der Daten. Bitte versuchen Sie es später erneut.</p>
        <p className="text-error">{error.message}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-10 text-base-content px-4">
      <h2 className="text-3xl font-bold text-center mb-8 text-primary">
        Kryptowährungskurse
      </h2>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Nach Name oder Symbol suchen..."
          className="w-full p-2 rounded bg-neutral text-base-content focus:outline-none focus:ring-2 focus:ring-primary"
          value={search}
          onChange={handleSearch}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-auto text-left">
          <thead>
            <tr className="bg-neutral">
              <th className="px-4 py-2 text-base-content">#</th>
              <th className="px-4 py-2 text-base-content">Name</th>
              <th className="px-4 py-2 text-base-content">Preis</th>
              <th className="px-4 py-2 text-base-content">24h Veränderung</th>
              <th className="px-4 py-2 text-base-content">Marktkapitalisierung</th>
            </tr>
          </thead>
          <tbody>
            {filteredCoins.length > 0 ? (
              filteredCoins.map((coin) => (
                <PriceRow key={coin.id} coin={coin} />
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4 text-base-content opacity-70">
                  Keine Ergebnisse gefunden.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Prices;