// src/components/PriceRow.js
import React from 'react';
import numeral from 'numeral';

function PriceRow({ coin }) {
  const {
    market_cap_rank,
    image,
    name,
    symbol,
    current_price,
    price_change_percentage_24h,
    market_cap,
  } = coin;

  const priceChangeClass =
    price_change_percentage_24h > 0 ? 'text-success' : 'text-error';

  return (
    <tr className="border-b border-base-300 hover:bg-neutral">
      <td className="px-4 py-2">{market_cap_rank}</td>
      <td className="px-4 py-2 flex items-center">
        <img src={image} alt={name} className="w-6 h-6 mr-2" />
        <span className="font-bold">{name}</span>
        <span className="uppercase text-gray-400 ml-2">({symbol})</span>
      </td>
      <td className="px-4 py-2">
        ${numeral(current_price).format('0,0.00')}
      </td>
      <td className={`px-4 py-2 ${priceChangeClass}`}>
        {price_change_percentage_24h.toFixed(2)}%
      </td>
      <td className="px-4 py-2">
        ${numeral(market_cap).format('0,0')}
      </td>
    </tr>
  );
}

export default PriceRow;