// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

function Navbar() {
  const { currentUser } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <nav className="bg-neutral p-4 sticky top-0 z-50">
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/" className="text-primary text-2xl font-bold">
          Cryptsight
        </Link>
        <div className="hidden md:flex space-x-4">
          <Link to="/prices" className="text-base-content hover:text-primary">
            Kurse
          </Link>
          <Link to="/analysis" className="text-base-content hover:text-primary">
            Analysen
          </Link>
          <Link to="/blog" className="text-base-content hover:text-primary">
            Blog
          </Link>
          {currentUser && (
            <Link to="/profile" className="text-base-content hover:text-primary">
              Profil
            </Link>
          )}
        </div>
        <div className="hidden md:flex items-center space-x-4">
          {currentUser ? (
            <>
              <span className="text-base-content">Hallo, {currentUser.email}</span>
              <button onClick={handleLogout} className="text-base-content hover:text-primary">
                Ausloggen
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-base-content hover:text-primary">
                Einloggen
              </Link>
              <Link to="/signup" className="text-base-content hover:text-primary">
                Registrieren
              </Link>
            </>
          )}
        </div>
        {/* Mobiles Menü Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-base-content hover:text-base-content focus:outline-none"
          >
            {/* Icon für das Menü */}
            <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
              {isMenuOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      {/* Dropdown-Menü für mobile Geräte */}
      {isMenuOpen && (
        <div className="md:hidden bg-neutral">
          <Link to="/prices" className="block px-4 py-2 text-base-content hover:text-primary">
            Kurse
          </Link>
          <Link to="/analysis" className="block px-4 py-2 text-base-content hover:text-primary">
            Analysen
          </Link>
          <Link to="/blog" className="block px-4 py-2 text-base-content hover:text-primary">
            Blog
          </Link>
          {currentUser && (
            <Link to="/profile" className="block px-4 py-2 text-base-content hover:text-primary">
              Profil
            </Link>
          )}
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-base-content hover:text-base-content text-left w-full"
            >
              Ausloggen
            </button>
          ) : (
            <>
              <Link to="/login" className="block px-4 py-2 text-base-content hover:text-base-content">
                Einloggen
              </Link>
              <Link to="/signup" className="block px-4 py-2 text-base-content hover:text-base-content">
                Registrieren
              </Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;