// src/data/posts.js
export const posts = [
  {
    id: 1,
    title: 'Die Zukunft von Bitcoin: Was erwartet uns?',
    excerpt:
      'Ein tiefer Einblick in die möglichen Entwicklungen von Bitcoin in den nächsten Jahren...',
    content: `
## Einführung

Bitcoin hat seit seiner Einführung im Jahr 2009 einen langen Weg zurückgelegt. In diesem Artikel werfen wir einen Blick auf die potenzielle Zukunft von Bitcoin und welche Faktoren seine Entwicklung beeinflussen könnten.

## Adoption durch Institutionen

Immer mehr institutionelle Investoren interessieren sich für Bitcoin als Wertanlage. Dieser Trend könnte die Akzeptanz weiter steigern und den Preis langfristig beeinflussen.

## Technologische Fortschritte

Die Entwicklung von Layer-2-Lösungen wie dem Lightning Network verspricht schnellere und günstigere Transaktionen, was die Nutzung von Bitcoin im Alltag fördern könnte.

## Regulatorische Herausforderungen

Regulierungen weltweit könnten die Nutzung von Bitcoin sowohl positiv als auch negativ beeinflussen. Es bleibt abzuwarten, wie sich die Gesetzgebung entwickelt.

## Fazit

Die Zukunft von Bitcoin ist spannend und ungewiss. Investoren sollten die Entwicklungen aufmerksam verfolgen und fundierte Entscheidungen treffen.
`,
    date: '14. November 2023',
    image: '/images/bitcoin-future.webp',
  },
  {
    id: 2,
    title: 'Ethereum 2.0 und seine Auswirkungen auf die Blockchain-Welt',
    excerpt:
      'Ein Überblick über das Upgrade von Ethereum und was es für Entwickler und Investoren bedeutet...',
    content: `
## Was ist Ethereum 2.0?

Ethereum 2.0 ist ein umfangreiches Upgrade des Ethereum-Netzwerks, das darauf abzielt, die Skalierbarkeit, Sicherheit und Nachhaltigkeit zu verbessern.

## Proof of Stake

Der Wechsel von Proof of Work zu Proof of Stake reduziert den Energieverbrauch und ermöglicht schnellere Transaktionen.

## Sharding

Durch Sharding wird das Netzwerk in kleinere Teile aufgeteilt, um die Verarbeitung von Transaktionen zu beschleunigen.

## Auswirkungen auf Entwickler

Smart Contracts und dezentrale Anwendungen (dApps) können effizienter und kostengünstiger betrieben werden, was Innovationen fördert.

## Auswirkungen auf Investoren

Investoren könnten von einer Wertsteigerung profitieren, aber es gibt auch Risiken, die mit der Umstellung verbunden sind.

## Schlussfolgerung

Ethereum 2.0 könnte die Blockchain-Welt revolutionieren. Es ist wichtig, die Entwicklungen zu beobachten und die Chancen zu erkennen.
`,
    date: '10. November 2023',
    image: '/images/ethereum-upgrade.webp',
  },
  // Weitere Posts können hier hinzugefügt werden
];