// src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div>
      {/* Hero-Bereich */}
            <div className="bg-neutral text-base-content py-20">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-5xl font-bold mb-4 text-primary">Willkommen bei Cryptsight</h1>
          <p className="text-xl mb-8">
            Ihre Quelle für aktuelle Krypto-Kurse, Analysen und Vorhersagen.
          </p>
          <Link
            to="/analysis"
            className="bg-primary text-base-content px-6 py-3 rounded-full font-semibold hover:bg-primary-focus"
          >
            Jetzt Analysen entdecken
          </Link>
        </div>
      </div>
      {/* Inhalt */}
            <div className="container mx-auto px-4 py-10 bg-base-100">
        <h2 className="text-3xl font-bold text-center text-base-content mb-8">Aktuelle Highlights</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Beispielkarten */}
          <div className="bg-neutral rounded-lg p-6 text-base-content">
            <h3 className="text-2xl font-bold mb-2">Bitcoin erreicht neues Allzeithoch</h3>
            <p>
              Bitcoin hat heute ein neues Allzeithoch erreicht. Experten sagen, dass...
            </p>
          </div>
          <div className="bg-neutral rounded-lg p-6 text-base-content">
            <h3 className="text-2xl font-bold mb-2">Ethereum 2.0 steht bevor</h3>
            <p>
              Die lang erwartete Aktualisierung von Ethereum steht kurz bevor...
            </p>
          </div>
          <div className="bg-neutral rounded-lg p-6 text-base-content">
            <h3 className="text-2xl font-bold mb-2">Altcoins im Aufwind</h3>
            <p>
              Viele Altcoins verzeichnen derzeit starke Gewinne und ziehen Investoren an...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;