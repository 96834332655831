// src/pages/Profile.js
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { updateProfile } from 'firebase/auth';

function Profile() {
  const { currentUser } = useAuth();
  const [name, setName] = useState(currentUser.displayName || '');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateProfile(currentUser, {
        displayName: name,
      });

      setMessage('Profil erfolgreich aktualisiert.');
    } catch (error) {
      console.error(error);
      setMessage('Fehler beim Aktualisieren des Profils.');
    }
  };

  return (
    <div className="container mx-auto p-4 text-base-content">
      <h1 className="text-3xl mb-4">Mein Profil</h1>
      {message && <p className="mb-4">{message}</p>}
      <div className="flex flex-col md:flex-row items-center mb-6">
        <div>
          <h2 className="text-2xl font-bold">{name}</h2>
          <p>{currentUser.email}</p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="bg-neutral p-6 rounded-lg">
        <div className="mb-4">
          <label className="block mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 rounded bg-base-200 text-base-content focus:outline-none focus:ring-2 focus:ring-accent"
          />
        </div>
        <button
          type="submit"
          className="bg-primary text-base-content px-4 py-2 rounded font-semibold hover:bg-primary-focus"
        >
          Aktualisieren
        </button>
      </form>
    </div>
  );
}

export default Profile;